import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import MuiButton from '@glass/web/components/base/Button';
import alpha from '@glass/web/modules/theme/alpha';
import makeStyles from '@glass/web/modules/theme/makeStyles';

import Tracking from '@glass/shared/modules/tracking/Tracking';
import useFunctionTracking from '@glass/shared/modules/tracking/useFunctionTracking';

// todo: remove this once we have a better solution
const greyVariants = {
  contained: (theme) => ({
    color: theme.palette.getContrastText(theme.palette.grey[300]),
  }),
  outlined: (theme) => ({
    color: theme.palette.text.primary,
    borderColor:
      theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
    '&.Mui-disabled': {
      border: `1px solid ${theme.palette.action.disabledBackground}`,
    },
    '&:hover': {
      borderColor:
        theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)',
      backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
    },
  }),
  text: (theme) => ({
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.primary, theme.palette.action.hoverOpacity),
    },
  }),
};

const useStyles = makeStyles()((theme, { variant, color }) => ({
  button: color === 'grey' && greyVariants[variant] ? greyVariants[variant]?.(theme) : {},
}));

const useAriaLabel = ({ children }) =>
  useMemo(() => {
    if (typeof children === 'string') {
      return children;
    }
    return 'button';
  }, [children]);

const Button = React.forwardRef(function Button(
  {
    variant,
    color,
    className,
    trackProps: trackPropsProp,
    onClick,
    ariaLabel: ariaLabelProp,
    children,
    ...otherProps
  },
  ref,
) {
  const { classes, cx } = useStyles({ variant, color });
  const ariaLabel = useAriaLabel({ children });

  const trackProps = useCallback(
    (...args) => ({
      action: 'button',
      ...(typeof trackPropsProp === 'function' ? trackPropsProp(...args) : trackPropsProp),
    }),
    [trackPropsProp],
  );

  const handleClickTracked = useFunctionTracking(Tracking.EVENTS.CLICK, trackProps, onClick);

  return (
    <MuiButton
      aria-label={ariaLabelProp || ariaLabel}
      {...otherProps}
      ref={ref}
      className={cx(className, classes.button)}
      color={color}
      variant={variant}
      onClick={handleClickTracked}
    >
      {children}
    </MuiButton>
  );
});

Button.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  trackProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  variant: PropTypes.string,
};

Button.defaultProps = {
  ariaLabel: 'button',
  trackProps: null,
};

Button.displayName = 'Button';

export default Button;
